import React from 'react'
import PlayStoreButton from '../images/play_store.png'
import AppStoreButton from '../images/app_store.png'

const StoreEmbedButtons = ({ className }) => {
	return (
		<div className={`flex ${className || ''}`}>
			<a href="https://apps.apple.com/us/app/classify-school-planner/id1463780331?itsct=apps_box_badge&amp;itscg=30200">
				<img src={AppStoreButton} />
			</a>
			<a href="https://play.google.com/store/apps/details?id=com.principal.classify">
				<img src={PlayStoreButton} />
			</a>
		</div>
	)
}

export default StoreEmbedButtons
