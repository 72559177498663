import React from 'react'
import Container from '../components/Container'
import IndexDark from '../components/layouts/dark'
import NewFooterImage from '../images/mission_cards.png'
import { Helmet } from 'react-helmet'
import AlertConfig from '../ALERTBAR_CONFIG'
import StoreEmbedButtons from '../components/StoreEmbedButtons'

function mission(props) {
	return (
		<>
			<Helmet>
				<title>Classify — Our Mission</title>
				<meta
					name="description"
					content="Free of charge, we aim to provide a digital organisation tool suitable for every kind of student. Whether you're the organized nerd that always raises their hand, or the sleepy procrastinator that thrives off energy drinks, we want to help you."
				/>
				<link rel="canonical" href="https://classify.org.uk/mission" />

				<meta property="og:title" content="Classify — Our Mission" />
				<meta
					property="og:url"
					content="https://classify.org.uk/mission"
				/>
				<meta
					property="og:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="og:description"
					content="Free of charge, we aim to provide a digital organisation tool suitable for every kind of student. Whether you're the organized nerd that always raises their hand, or the sleepy procrastinator that thrives off energy drinks, we want to help you."
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta
					property="twitter:title"
					content="Classify — Our Mission"
				/>
				<meta
					name="twitter:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="twitter:description"
					content="Free of charge, we aim to provide a digital organisation tool suitable for every kind of student. Whether you're the organized nerd that always raises their hand, or the sleepy procrastinator that thrives off energy drinks, we want to help you."
				/>
			</Helmet>
			<IndexDark navy>
				<section
					id="mission"
					className="bg-mission flex flex-col"
					style={{
						minHeight: `calc(100vh - ${
							AlertConfig.enabled ? '100px' : '100vh'
						})`,
					}}
				>
					<div>
						<div className="mission-container mx-auto relative px-4 md:px-12 xl:w-4/5 2xl:w-3/5 ">
							<div className="grid h-full gap-8 2xl:gap-16 text-center pt-12 lg:pt-24">
								<h1 className="font-valorant text-white mission-header">
									🚀 MISSION
								</h1>
								<article className="lg:pr-10">
									<p className="pb-5">
										Founded by students frustrated with the
										tedious nature of academic organisation,
										Classify strives to challenge this
										notion through
										<strong>
											{' '}
											intuitive design and convenience
										</strong>
										.
									</p>
									<p className="pb-5">
										Free of charge, we aim to provide a
										digital organisation tool suitable for
										<strong> every kind of student</strong>.
										Whether you're the organized nerd that
										always raises their hand, or the sleepy
										procrastinator that thrives off energy
										drinks, we want to help you.
									</p>
									<p>
										Our vision of making academic
										organization the{' '}
										<strong>
											easiest step in success{' '}
										</strong>
										remains at the heart of every update.
										Ultimately, we want to replace pen and
										paper and become a staple piece of{' '}
										<strong>
											every student's success story
										</strong>
										.
									</p>
								</article>

								<StoreEmbedButtons className="store-embed-mission" />
							</div>
						</div>
					</div>
					<div className="hidden md:block w-full mx-auto mt-10">
						<div className="relative xl:pt-[17.83%]">
							<img
								src={NewFooterImage}
								className="absolute bottom-0"
								alt="footer image"
							/>
						</div>
					</div>
				</section>
			</IndexDark>
		</>
	)
}

export default mission
